import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';

import ReactGA from 'react-ga4';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AppBar from './components/navigation/AppBar';
import Footer from './components/Footer';
import Home from './components/home/Home';
import Audits from './components/audits/Audits';
import Tools from './components/tools/Tools';
import CVE from './components/CVE/CVE';
import SCSec from './components/scsec/SCSec';
import SCSecCosmosHardening from './components/scsec-pages/scsec-cosmos-hardening/SCSecCosmosHardening';
import SCSecCosmosV1 from './components/scsec-pages/scsec-cosmos-v1/SCSecCosmosV1';
import SCSecCelestiaTorchV1 from './components/scsec-pages/scsec-celestia-torch-v1/SCSecCelestiaTorchV1';
import SCSecNMT from './components/scsec-pages/scsec-celestia-nmt-v1/SCSecNMT';
import SCSecDydxNPMCompromise from './components/scsec-pages/scsec-dydx-npm-compromise/SCSecDydxNPMCompromise';
import SCSecTendermintP2PAuditV1 from './components/scsec-pages/scsec-tendermint-p2p-v1/SCSecTendermintP2PAuditV1';
import SCSecCosmosGaiaRhoV1 from './components/scsec-pages/scsec-cosmos-gaia-rho/SCSecCosmosGaiaRhoV1';
import SCSecGoCodingGuide from './components/scsec-pages/scsec-cosmos-go-coding-guide/SCSecGoCodingGuide';
import SCSecICS23AuditV1 from './components/scsec-pages/scsec-cosmos-ics23-go-audit-v1/SCSecICS23AuditV1';
import SCSecUNIXFileHandleZombie from './components/scsec-pages/scsec-unix-file-handle-perms/SCSecUNIXFileHandleZombie';
import CosmosDec2023Report from './components/scsec-pages/cosmos-dec2023/CosmosDec2023';
import QuicksilverAuditsV1 from './components/audit-pages/quicksilver-audits-v1/QuickSilverAuditV1';
import QuicksilverAuditsV14 from './components/audit-pages/quicksilver-audits-v1.4/QuickSilverAuditV1_4';
import QuickSilverAuditXInterchainStakingV3 from './components/audit-pages/quicksilver-x-interchainstaking-audits-v3/QuickSilverAuditXInterchainStakingV3';
import CelestiaSMTAudits from './components/audit-pages/celestia-smt-audits/CelestiaSMTAudits';
import ChainguardCosmosAuditsV1 from './components/audit-pages/chainguard-cosmos-audits-v1/ChainguardCosmosAuditsV1';
import IgniteIncidenceResponse from './components/audit-pages/ignite-security-incidence-response/IgniteSecurityIncidenceResponse';
import CosmWasmAuditsV1 from './components/audit-pages/cosm-wasm-audits-v1/CosmosWasmAuditsV1';
import EvmosAuditsV1 from './components/audit-pages/evmos-audits-v1/EvmosAuditsV1';
import SCSecGoXSSConcatTextTemplate from './components/scsec-pages/go-xss-concat-text-template/GoXSSConcatTextTemplate';
import SCSecVarintDecodeLimitless from './components/scsec-pages/varint-decode-limitless/VarintDecodeLimitless';
import SCSecAMEXEarlybirdV1 from './components/scsec-pages/amex-earlybird-v1/AMEXEarlybird';

import MenuList from './components/navigation/MenuList';

ReactGA.initialize('G-1XDZ3TRQE0');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <BrowserRouter>
        <Box
          component="main"
          sx={{
            bgcolor: ({ palette }) => palette.mode === 'dark' ? palette.grey[800] : palette.grey[50],
            height: '100vh',
            width: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AppBar
            isMobile={mobile}
            onClickMenu={() => setOpenMenu(true)}
          />
          <MenuList
            mobile={true}
            open={openMenu}
            onClick={() => setOpenMenu(false)}
          />
          <Container
            maxWidth={false}
            sx={{ px: { xs: 0 }, mx: { xs: 0 } }}
          >
            <Routes>
              <Route
                path="/"
                element={<Home />}
              />

              <Route
                path="/audits"
                element={<Audits />}
              />

              <Route
                path="/tools"
                element={<Tools />}
              />

              <Route
                path="/cves"
                element={<CVE />}
              />

              <Route
                path="/scsec/"
                element={<SCSec />}
              />

              <Route
                path="/audits/quicksilver-audits-v1.4"
                element={<QuicksilverAuditsV14 />}
              />

              <Route
                path="/cosmos/dec2023"
                element={<CosmosDec2023Report />}
              />

              <Route
                path="/scsec/cosmos-ics23-audit-v1"
                element={<SCSecICS23AuditV1/>}
              />

              <Route
                path="/advisory/varint-decode-limitless"
                element={<SCSecVarintDecodeLimitless/>}
              />

              <Route
                path="/advisory/go-xss-concat-text-template"
                element={<SCSecGoXSSConcatTextTemplate />}
              />

              <Route
                path="/scsec/amex-earlybird-v1"
                element={<SCSecAMEXEarlybirdV1 />}
              />

              <Route
                path="/scsec/celestia-torch-v1"
                element={<SCSecCelestiaTorchV1 />}
              />

              <Route
                path="/scsec/cosmos-hardening"
                element={<SCSecCosmosHardening />}
              />

              <Route
                path="/scsec/cosmos-v1"
                element={<SCSecCosmosV1 />}
              />

              <Route
                path="/scsec/celestia-nmt-v1"
                element={<SCSecNMT />}
              />

              <Route
                path="/scsec/dydx-npm-compromise"
                element={<SCSecDydxNPMCompromise />}
              />

              <Route
                path="/scsec/tendermint-p2p-v1"
                element={<SCSecTendermintP2PAuditV1 />}
              />

              <Route
                path="/scsec/cosmos-go-coding-guide"
                element={<SCSecGoCodingGuide />}
              />

              <Route
                path="/scsec/cosmos-gaia-rho-v1"
                element={<SCSecCosmosGaiaRhoV1 />}
              />

              <Route
                path="/scsec/unix-file-handle-perms"
                element={<SCSecUNIXFileHandleZombie />}
              />

              <Route
                path="/audits/quicksilver-audits-v1"
                element={<QuicksilverAuditsV1 />}
              />

              <Route
                path="/audits/quicksilver-x-interchainstaking-audits-v3"
                element={<QuickSilverAuditXInterchainStakingV3 />}
              />

              <Route
                path="/audits/celestia-smt-audits"
                element={<CelestiaSMTAudits />}
              />

              <Route
                path="/audits/chainguard-cosmos-audits-v1"
                element={<ChainguardCosmosAuditsV1 />}
              />

              <Route
                path="/audits/ignite-security-incidence-response"
                element={<IgniteIncidenceResponse />}
              />

              <Route
                path="/audits/cosm-wasm-audits-v1"
                element={<CosmWasmAuditsV1 />}
              />

              <Route
                path="/audits/evmos-audits-v1"
                element={<EvmosAuditsV1 />}
              />

              <Route
                path="*"
                element={<p>Page not found</p>}
              />

            </Routes>
          </Container>
          <Footer />
        </Box>
      </BrowserRouter>
    </Box>
  );
}
